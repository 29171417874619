
.singleWork__name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 1.5;
  color: #333333;
}
.singleWork__tagline {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
}

.singleWork__details {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  text-align: start;
  color: #333333;
}
.detail__link{
    font-weight: bold !important;
}