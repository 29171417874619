* {
  margin: 0;
  box-sizing: border-box;
  font-family:  "Montserrat", sans-serif;
  font-weight: 400;
}

body{
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 100px;
  padding-bottom: 125px;
}

a,a:hover{
  text-decoration: none;
  color:unset;
}

.hover_img{
  border-radius: 8px;
  transition: all 0.65s;
  animation-delay: 0.25s !important;
}

.hover_img:hover{
  transform: scale(1.05);
}

.align-center{
  align-items: center;
}

.page__subheading {
    font-style: normal;
    font-weight: normal;
    color: #000000;
}

.page__heading {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 300;
  text-align: left;
  color: #000000;
  line-height: 1.5;
}
.img-fluid {
  max-width: 20vw;
  min-width: 50px
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}