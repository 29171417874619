.navbar-fixed-bottom {
  padding:0
}
.navbar.fixed-bottom .navbar-brand {
  font-size: 12px;
  color: black;
}

.icons {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-evenly;
}
.icons a {
  margin: 0 15px;
}

.circle {
  margin-top: 14px;
  height: 32px;
  width: 32px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons .circle svg {
  fill: white;
}


