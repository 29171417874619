.about-container {
  margin-top: 125px;
}
.about-container .row {
  align-items: start;
}
.about__img img {
  border-radius: 8px;
}

/* skills */
.skills__category {
  font-size: 18px;
  color: #666666;
}
.pill {
  background: #BDDCF0;
  border-radius: 13px;
  font-size: 12px;
  color: #121212;
  margin: 0 4px;
}
