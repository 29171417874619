

.case__hero {
  padding-top: 36px;
  padding-bottom: 36px;
  min-height: 472px;
}

.case__hero h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  color: #333333;
}
.case__hero > p {
  font-weight: normal;
  font-size: 23.04px;
  line-height: 32px;
  color: #333333;
}
.case__problem {
  margin-top: 36px;
}

.case__problem .row {
  align-items: center;
}

/* SUBPOINT */


.subpoint__text {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
}
.subpoint__heading {
  white-space: nowrap;
}

.research {
  margin-bottom: 0px;
}

.research__text {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.011em;
  text-align: center;
  color: #000000;
  text-indent: 16px;
}
.research__source {
  font-weight: 500;
  font-size: 12px;
  display: block;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.011em;
  color: #333333;
  opacity: 0.75;
}

.case__topic {
   padding: 16px 0;
}
.topic__color {
  background: #b9b9b9;
}

.subpoint__img .img-fluid {
  text-align: center;
  max-height: 65%;
  max-width: 85%;
}
.subpoint-container {
  margin-top: 16px;
}

.list {
  font-weight: 500;
}

#bold {
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 800%;
  width: auto;
  padding-top: 36px;
  padding-bottom: 36px;
}
.prototype__text {
  margin-top: 18px;
  font-weight: 600;
  font-size: 19.2px;
  line-height: 36px;
  color: #333333;
}

.highlight_no{
  font-family: "Montserrat", sans-serif;
  color: #6C63FF;
  font-size: 33px;
  font-weight: 400;

}
.highlight_text{
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23.04px;
  line-height: 32px;
  /* or 139% */
  align-items: center;
  color: #6C63FF;
}

.highlight_textint{
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23.04px;
  line-height: 32px;
  /* or 139% */

  align-items: center;
  color: #315EFF;
}

.caseStudyTable {
  margin-bottom: 36px;
}

.caseStudyTable tr td, 
.caseStudyTable tr th {
  padding: 4px 16px; 
  text-align: left; 
} 

blockquote {
    border:none;
    margin-bottom: 0px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote span {
  font-size: 16px;
}

blockquote span:before { 
  content: open-quote;
  font-weight: bold;
  font-size:18px;
  color:#889c0b;
} 
blockquote span:after { 
  content: close-quote;
  font-weight: bold;
  font-size:18px;
  color:#889c0b;
}