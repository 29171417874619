

.nowrap {
  white-space: nowrap;
}
.align-top {
  vertical-align: top;
}

/* HERO */

.hero__highlight {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: inherit;
  color: #fc004d;
}


.arrow-link { padding: 3px 0; text-align:center;}
.arrow-link span.text{
    font-size: 13px;
    font-weight: 500;
    font-family: inherit;
    width: auto;
    margin: 0px -5px 30px -5px;
    display: inline-block;
    background: #fff;
    cursor: pointer;
    color: #0a58ca;
    padding: 0 16px;
}

.arrow-link .separator {
    width: 30px;
    height: 2px;
    background-color: #0a58ca;
    margin: 0px auto;
    display: inline-block;
    vertical-align: middle;
    position:relative;
}
.arrow-link .separator:last-child:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    left: 0;
    border-radius: 100%;
    top: -9px;
}
.arrow-link .separator:first-child:before {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    border-bottom: 5px solid #0a58ca;
}

.arrow-link:hover span.text,
.arrow-link:hover span.text {
    color: red;
    outline: 0;
}

a.arrow-link:hover .separator {
    background-color: #0a58ca;
}
a.arrow-link:hover span.text, 
a.arrow-link:focus span.text {
    color: #0a58ca;
    outline: 0;
}

a.arrow-link:hover .separator:first-child:before,
a.arrow-link:hover .separator:first-child:before {
    border-bottom-color: #0a58ca;
}

.fixed-header-padding {
  padding-top: 100px;
}

.project__img {
  width: 100%;
}

.project__name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 49px;
  white-space: nowrap;
}

.project__desc {
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.68);
  margin-bottom: 50px;
}

.project__link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0a58ca;
  transition: all 0.5s;
  text-decoration: underline
}
.project__link:hover {
  color: #0d6efd;
  text-decoration: none;
}



/* Other Work */



/* media  queries */

@media screen and (max-width: 576px) {
  .hero-container h1 {
    font-size: 48px;
    line-height: 64px;
  }
 

  .hero__description {
    margin-bottom: 50px;
  }



  /* resize image for phone */
  /* .project__img {
    width: 280px;
  } */
}

.principles .img-fluid {
  max-width: 150px;
  min-width: 50px;
  padding: 5px 15px;
}
.principles ul {
  text-align: center;
  font-weight: 300;
}
.principles ul  {
  list-style: none;
  margin: 0;
  padding: 0;
}
.principles li {  
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  border-radius: 8px;
}
.principles li > div {
  background-color: #eee;
  border-radius: 30px;
  width: 100%;
  font-size: 14px;
  height: 160px;
  display: block;
  line-height: 14px;
  margin: 0 5px;
  padding: 10px;
  text-decoration: none;
}
.principles li i {
  font-size: 3em;
  color: #448aaf;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.principles .title {
  font-weight: 500;
  height: 20%;
  line-height: 1.5;
}
.principles .subtitle {
  font-size: 14px;
  font-weight: 400;
}



@media screen and (max-width: 1200px) {
  .hero-container h1 {
    font-size: 42px;
  }
}

@media screen and (max-width: 770px) {
  .hero-container h1 {
    font-size: 36px;
    line-height: 64px;
  }
} 